import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import { useQuery } from "react-query"
import { useForm } from "react-hook-form"
import { css } from "@emotion/core"
import tw from "twin.macro"
import cn from "classnames"
import fetch from "node-fetch"
import { MdKeyboardArrowRight } from "react-icons/md"

import Layout from "../components/Layout"
import TextRender from "../components/TextRender"
import Curve from "../components/Curve"
import { linkResolver } from "../../prismic.config"
import Collapse from "../components/Collapse"
import { SearchForm, SearchResults } from "./helpdesk"

export default function Article({ data, pageContext }) {
  const [term, setTerm] = useState("")
  const search = useForm()
  useEffect(() => {
    fetch("https://functions.greatcontent.com/.netlify/functions/search")
  }, [])
  const query = useQuery(
    ["search", term],
    async () => {
      return fetch(
        "https://functions.greatcontent.com/.netlify/functions/search",
        {
          method: "POST",
          body: JSON.stringify({ term, category: pageContext.category_name }),
        }
      ).then((res) => res.json())
    },
    {
      enabled: Boolean(term.trim().length),
    }
  )

  const seoData = {
    meta_title: get(data, "article.title"),
    type: "article",
    meta_description:
      get(data, "article.description_text", "").slice(0, 120) +
        get(data, "article.description_text", "").length >
      120
        ? " ..."
        : "",
    meta_keywords: "",
  }

  return (
    <Layout transparentNavigation pageContext={pageContext} seo={seoData}>
      <div className="pt-40 pb-20 phone:pt-32 bg-light ">
        <div
          className="mx-auto prose text-center"
          css={css`
            h1 {
              ${tw`text-2xl`}
            }
          `}
        >
          <TextRender text={get(data, "helpdesk.data.title.raw")} />
        </div>
        <div className="container lg:max-w-6xl">
          <SearchForm
            data={data}
            setTerm={setTerm}
            search={search}
            query={query}
          ></SearchForm>
        </div>
      </div>
      <Curve className="text-white" />

      <div className="container lg:max-w-6xl relative z-10 -mt-5 text-center">
        <div className="inline-flex flex-wrap items-center justify-center px-6 py-2 text-sm font-semibold bg-white rounded text-light-text phone:px-3 filter-shadow">
          <Link>FAQ</Link> <MdKeyboardArrowRight />{" "}
          {!query.isSuccess && (
            <>
              <Link
                className="hover:text-primary"
                to={linkResolver({
                  type: "page",
                  lang: pageContext.lang,
                  uid: `helpdesk/${pageContext.category_slug}`,
                })}
              >
                {pageContext.category_name}
              </Link>{" "}
              <MdKeyboardArrowRight />
              <span className="text-primary">{pageContext.folder_name}</span>
            </>
          )}
          {query.isSuccess && (
            <>
              <span className="text-primary">
                {get(data, "helpdesk.data.search_results")}
              </span>
            </>
          )}
        </div>
      </div>
      {query.isSuccess && (
        <SearchResults
          helpdesk={get(data, "helpdesk")}
          term={term}
          data={query.data}
          pageContext={pageContext}
        />
      )}
      {/* <pre>{JSON.stringify(query, null, 2)}</pre> */}
      {!query.isSuccess && (
        <div className="container   py-20">
          <div className="relative items-start row lg:flex-nowrap phone:space-y-6">
            <div className="sticky w-full col lg:w-80 tablet:mt-8 top-20 flex-shrink-0">
              <div className="p-4 overflow-hidden border rounded-md">
                <h3 className="mb-4 text-lg font-semibold">
                  {pageContext.category_name}
                </h3>

                <div className="mb-6">
                  <Sidebar
                    pageContext={pageContext}
                    folder={pageContext.folder_name}
                    data={data.sidebar}
                  ></Sidebar>
                </div>
              </div>
            </div>
            <div
              className="w-full col tablet:order-first"
              css={css`
                width: 100%;
                @media (min-width: 1024px) {
                  width: calc(100% - 20rem);
                }
              `}
            >
              <div className="prose max-w-none">
                <div className="space-y-2 mb-6">
                  <h2 tw="text-xl! my-0!">{get(data, "article.title")}</h2>
                  <p tw="text-sm!">
                    {data.article.modified} {data.article.updated_at}
                  </p>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: data.article.description }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

function Sidebar({ folder, data, pageContext }) {
  const [isOpen, setIsOpen] = useState(folder)
  return (
    <div className="space-y-2 text-sm ">
      {data.group
        .sort((a, b) => {
          return a.nodes[0].folderIndex > b.nodes[0].folderIndex ? 1 : -1
        })
        .map((item) => {
          return (
            <div key={item.fieldValue}>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => {
                  isOpen == item.fieldValue
                    ? setIsOpen(null)
                    : setIsOpen(item.fieldValue)
                }}
              >
                <span
                  className={cn({
                    "text-primary": isOpen == item.fieldValue,
                  })}
                >
                  {item.fieldValue}
                </span>
                <span className="text-light-text ml-4">
                  ({item.totalCount})
                </span>
              </div>
              <Collapse
                initialyOpen={isOpen == item.fieldValue}
                isOpen={isOpen == item.fieldValue}
              >
                <ul className="space-y-3 divide-y divide-gray-200   py-2 pl-3 pr-8">
                  {item.nodes.map((article) => {
                    return (
                      <li key={article.slug}>
                        <Link
                          activeClassName={"text-primary"}
                          className="hover:text-primary  leading-none pt-3 block"
                          to={linkResolver({
                            type: "page",
                            lang: pageContext.lang,
                            uid: `helpdesk/${pageContext.category_slug}/${article.slug}`,
                          })}
                        >
                          {article.title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </Collapse>
            </div>
          )
        })}
    </div>
  )
}

export const query = graphql`
  query($id: String, $lang: String, $category_name: String) {
    article(id: { eq: $id }) {
      title
      description
      description_text
      updated_at(formatString: "LLLL", locale: $lang)
    }
    helpdesk: prismicHelpdesk(data: { category: { eq: $category_name } }) {
      data {
        category
        modified
        search_button
        search_results
        search_results_text
        search_text
        title {
          raw
        }
      }
    }
    sidebar: allArticle(
      filter: { category: { name: { eq: $category_name } } }
    ) {
      group(field: folder___name) {
        fieldValue
        totalCount
        nodes {
          title
          slug
          folderIndex
        }
      }
    }
  }
`
